/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { DateTime } from "luxon";
import { css } from "@emotion/react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import { useApp, useWindowDimensions } from "~hooks";
import { Button, Grid, SwiperCarousel } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 1.5rem 0 0;

  ${MEDIA_QUERIES?.desktop} {
    padding: 3rem 0 3rem;
  }
`;

/** ============================================================================
 * @component
 * Sample component receiving heading and body text.
 */
const Reviews = ({
  data: { backgroundColor, fontColor, heading, reviews }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { setReviewOverlayActive } = useApp();

  const { isDesktop } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // variables

  const itemsPerPage = 3;
  const pages = [];

  for (let i = 0; i < reviews.length; i += itemsPerPage) {
    pages.push(reviews.slice(i, i + itemsPerPage));
  }

  // ---------------------------------------------------------------------------
  // render

  return (
    <Wrapper
      css={css`
        background: ${backgroundColor?.hex};
        color: ${fontColor?.hex};
      `}
    >
      <Grid>
        <div
          css={[
            css`
              grid-column: span 6 / span 6;
              grid-column-start: 4;
              position: relative;

              ${MEDIA_QUERIES?.desktop} {
                grid-column: span 6 / span 6;
                grid-column-start: 4;
              }
            `
          ]}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              text-transform: uppercase;
            `}
          >
            <h2
              css={css`
                line-height: 130%;
              `}
              className="h1"
            >
              {heading}
            </h2>
          </div>
        </div>
      </Grid>

      <Grid
        _css={css`
          position: relative;
          padding: 2.5rem 0 0;

          ${MEDIA_QUERIES?.desktop} {
            padding: 4rem 0 0;
          }
        `}
      >
        <div
          css={css`
            grid-column: span 12 / span 12;
            position: relative;

            ${MEDIA_QUERIES?.desktop} {
              grid-column: span 12 / span 12;
            }
          `}
        >
          <SwiperCarousel
            _css={css`
              width: 100%;
              position: relative;
              display: block;
            `}
            options={{
              initialSlide: 0
            }}
            nav={({ current, next, prev, to }) => (
              <>
                <div
                  css={css`
                    width: 100%;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 1.5rem;
                  `}
                >
                  {pages.map((_, pageIndex) => (
                    <button
                      type="button"
                      css={css`
                        opacity: ${current === pageIndex ? 1 : 0.3};

                        width: 1.75rem;
                        height: 2rem;
                        position: relative;
                        display: block;
                        margin-top: 0;
                        cursor: pointer;

                        ${MEDIA_QUERIES?.desktop} {
                          margin-top: 2rem;
                        }

                        ${MEDIA_QUERIES?.hoverable} {
                          &:hover {
                            text-decoration: underline;
                          }
                        }
                      `}
                      onClick={() => to(pageIndex + 1)}
                    >
                      <p className="b3">{pageIndex + 1}</p>
                    </button>
                  ))}
                </div>
              </>
            )}
            slides={pages.map((page) => (
              <ul
                css={css`
                  width: 100%;
                  padding: 0 1rem;

                  ${MEDIA_QUERIES?.desktop} {
                    width: 633px;
                    margin: 0 auto;
                    padding: 0;
                  }
                `}
              >
                {page.map((review) => {
                  let date;

                  if (review?.date) {
                    date = DateTime.fromFormat(review.date, `yyyy-MM-dd`);
                  }

                  return (
                    <li
                      css={css`
                        width: 100%;
                        position: relative;
                        margin-bottom: 3rem;

                        ${MEDIA_QUERIES?.desktop} {
                          margin-bottom: 4.5rem;
                        }
                      `}
                    >
                      <article>
                        <h3
                          css={css`
                            text-align: center;
                            text-transform: uppercase;
                            font-size: 14px;
                            line-height: 19px;

                            ${MEDIA_QUERIES?.desktop} {
                              font-size: 16px;
                              line-height: 20px;
                            }
                          `}
                          className="h4"
                        >
                          {review.title}
                        </h3>

                        <p
                          css={css`
                            margin: 0.5rem 0 0.75rem;
                            line-height: 20px;

                            ${MEDIA_QUERIES?.desktop} {
                              line-height: 22px;
                            }
                          `}
                          className="b3"
                        >
                          {review.content}
                        </p>

                        <p
                          className="b3"
                          css={css`
                            text-align: center;
                            font-style: italic;
                            line-height: 20px;

                            ${MEDIA_QUERIES?.desktop} {
                              line-height: 22px;
                            }
                          `}
                        >
                          <span>{review.person}</span>

                          {date && (
                            <span>
                              {` `}on{` `}
                              {` `}
                              {date.toLocaleString(DateTime.DATE_MED)}
                            </span>
                          )}

                          <span>.</span>
                        </p>

                        {review?.rating && (
                          <div
                            css={css`
                              position: relative;
                              margin-top: 0.5rem;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              gap: 0.125rem;
                            `}
                          >
                            {/* {Array(parseInt(review.rating))
                              .fill(null)
                              .map((star) => (
                                <Star
                                  css={css`
                                    width: 1rem;
                                  `}
                                  fill="black"
                                />
                              ))} */}
                            {Array(parseInt(review.rating))
                              .fill(null)
                              .map((star) => (
                                <p className="text-button">★</p>
                              ))}
                          </div>
                        )}
                      </article>
                    </li>
                  );
                })}
              </ul>
            ))}
          />

          {isDesktop && (
            <div
              css={css`
                width: 100%;
                position: absolute;
                bottom: 0.7rem;
                right: 0;
                left: 0;
              `}
            >
              <Grid>
                <div
                  css={css`
                    grid-column: span 12 / span 12;
                  `}
                >
                  <Button
                    _css={css`
                      padding: 0 3rem;
                    `}
                    onClick={() => setReviewOverlayActive(true)}
                    border="var(--color-black)"
                    text="Write A Review"
                  />
                </div>
              </Grid>
            </div>
          )}
        </div>
      </Grid>

      {!isDesktop && (
        <Grid>
          <div
            css={css`
              grid-column: span 12 / span 12;
              margin-top: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Button
              _css={css`
                padding: 0 3rem;
              `}
              onClick={() => setReviewOverlayActive(true)}
              border="var(--color-black)"
              text="Write A Review"
            />
          </div>
        </Grid>
      )}
    </Wrapper>
  );
};

export const query = graphql`
  fragment ReviewsFragment on SanityReviews {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading

    reviews {
      title
      rating
      person
      date
      content
    }
  }
`;

export default Reviews;
